import $ from 'jquery';
import { getAdJqXHR, postAdJqXHR } from '../api';
import { Advertisement } from '../model';

interface Res {
  total: number;
  data: Advertisement[];
}

$(() => {
  // 広告 取得
  const adJqXHR = getAdJqXHR();
  adJqXHR.done((res: Res) => {
    const adList = res.data;
    const uniconectUrl = 'https://www.giftnet.jp';
    let html = '';
    adList.forEach((ad) => {
      const img = `
    <div class='ad-content'>
        <a
          href="${ad.ad_url}"
          class="advertisement ad-link"
          target="_blank"
          rel="noopener noreferrer"
          data-id="${ad.ad_id}"
        >
          <img
            src="${uniconectUrl}${ad.ad_img}"
            class="ad-img"
            alt="広告${ad.ad_id}"
          />
          <span class='ad-pr'>PR</span>
        </a>
    </div>
      `;
      html += img;
    });
    $('#adList').html(html);

    // 広告 クリック時
    $(document).on('click', '.advertisement', function (e) {
      const id = parseInt($(this).data('id'));
      postAdJqXHR(id).always(() => {
        console.log(`advertisement No.${id}`);
      });
    });
  });
});

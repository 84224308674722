if (!Array.prototype.find) {
  Array.prototype.find = function (predicate: {
    call: (arg0: any, arg1: any, arg2: number, arg3: any) => any;
  }) {
    if (this === null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value: any;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    // eslint-disable-next-line consistent-return
    return undefined;
  };
}

import $ from 'jquery';
import { getSfJqXHR, postSfJqXHR } from '../api';
import { SfContent } from '../model';

interface Res {
  total: number;
  data: SfContent[];
}

$(() => {
  // ビジネスガイド社からのお知らせ (旧特集 取得)
  const sfJqXHR = getSfJqXHR();
  sfJqXHR.done((res: Res) => {
    const sfList = res.data;
    const uniconectUrl = 'https://www.giftnet.jp';
    let html = '';
    sfList.forEach((sf) => {
      const img = `
<div class='sf-content'>
        <a
          href='${sf.sf_url}'
          class="sfContent d-block"
          target="_blank"
          rel="noopener noreferrer"
          data-id="${sf.sf_id}"
        >
          <img
            src="${uniconectUrl}${sf.sf_img}"
            class="d-block sf-img"
            alt="特集${sf.sf_id}"
            style= "margin: auto;"
          />
        </a>
</div>
      `;
      html += img;
    });
    $('#sfList').html(html);

    // ビジネスガイド社からのお知らせ (旧特集 取得) クリック時
    $(document).on('click', '.sfContent', function (e) {
      const id = parseInt($(this).data('id'));
      postSfJqXHR(id).always(() => {
        console.log(`sfContent No.${id}`);
      });
    });
  });
});

// bootstrap
import 'bootstrap';

// fontawesome
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

// styles
import '../scss/index.scss';

// js
import './util/polyfill.ts'; // IE11でfindメソッドを動作させる
import './util/header.ts'; // ヘッダーのトグルメニュー制御・言語設定
import './util/menuUrl.ts'; // メニュー URL 切り替え
import './util/ad.ts'; // 広告 表示
import './util/sc.ts'; // スペシャルコンテンツ 表示
import './util/sf.ts'; // 特集 表示

import $ from 'jquery';

interface IProps {
  // value: number | string;
  // name: string;
  // className: string;
  // active?: boolean;
  id: number;
  url: string;
  newflag: boolean;
  img: string;
  title: string;
  period_notice: string;
  content_name: string;
  description: string;
}

const url: string = 'https://www.giftnet.jp/';

const SpecialContentComponent = (props: IProps): string => {
  // const active = props.active === true ? 'active' : '';
  // <div class='${props.className} card pointer col-6 col-md-3 mb-2 ${active}' data-value='${props.value}'>
  //           <div class='card-body label-body'>
  //               <span class='label-text d-block'>${props.name}</span>
  //           </div>
  //       </div>

  // newflagがtrueのときは左上にnew表示する
  let specialCardDiv: string = '';
  let specialEnddateDiv: string = '';
  if (props.newflag)
    specialCardDiv += "<div class=' card special-card card-shadow new-flag'>";
  else specialCardDiv += "<div class=' card special-card card-shadow'>";

  if (props.period_notice) {
    specialEnddateDiv += `<span class='special-date special-date-pc'> ${props.period_notice}</span>`;
  }
  specialEnddateDiv += `<h5 class='card-title special-title'>${props.title}</h5>`;
  if (props.period_notice) {
    specialEnddateDiv += `<span class='special-date special-date-mb'> ${props.period_notice}</span>`;
  }
  return `
 <div class='special-content'>
     <a data-id='${props.id}' class='scContent card-link ' href='${props.url}'>
            ${specialCardDiv}
              <img
                aria-label='Placeholder: Image cap'
                class='bd-placeholder-img card-img-top special-img'
                focusable='false'
                height='180'
                preserveAspectRatio='xMidYMid slice'
                role='img'
                src='${url}${props.img}'
                alt='${props.content_name}'
                width='100%'
              />
              <div class='special-body card-body'>
                ${specialEnddateDiv}
                <p class='card-text special-text d-none d-sm-block'>
                ${props.description}
                </p>
              </div>
            </div>
          </a>
</div>
  `;
};
export default SpecialContentComponent;

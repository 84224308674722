import $ from 'jquery';
import { getScJqXHR, postScJqXHR } from '../api';
import { ScContent } from '../model';
import { getParam } from './urlParam';

import SpecialContentComponent from '../components/SpecialContentComponent';

import { checkSlickSpecial } from './slick';

interface Res {
  total: number;
  data: ScContent[];
}

$(() => {
  // 特集 取得
  const scJqXHR = getScJqXHR();
  scJqXHR.done((res: Res) => {
    // slickを一旦解除
    const scList = res.data;
    let html = '';
    scList.forEach((sc) => {
      if (sc.top_banner) {
        let img: string = '';
        img += SpecialContentComponent({
          id: sc.sc_id,
          url: sc.sc_url,
          img: sc.sc_img,
          newflag: sc.sc_newflag,
          title: sc.sc_title,
          period_notice: sc.period_notice,
          content_name: sc.content_name,
          description: sc.sc_description,
        });
        html += img;
      }
    });
    $('#scList').html(html);

    // DOM生成が終わったのでここで再初期化
    checkSlickSpecial();
    // 特集 クリック時
    $(document).on('click', '.scContent', function (e) {
      const id = parseInt($(this).data('id'));
      postScJqXHR(id).always(() => {
        console.log(`scContent No.${id}`);
      });
    });
  });

  // 検索結果ページ 特集表示
  const pContent = getParam('content');
  if (pContent) {
    scJqXHR.done((res: Res) => {
      const scList = res.data;
      const sc = scList.find((item) => item.sc_key === pContent) ?? null;
      if (sc) {
        $('#special-content-img').html(
          `<img src="${sc.img_background}" alt="特集${sc.sc_key}" class="sc-topimg">`
        );
        $('#special-content-img').show();
        if (sc.sc_img2 != null) {
          $('#special-content-description').html(
            `<a href="${sc.sc_url2}" target="_blank">
              <img src="${sc.sc_img2}" alt="説明${sc.sc_key}" class="sc-topimg">
            </a>`
          );
          $('#special-content-description').show();
        }
        //特集ページでは、再検索ボックスを非表示にする
        $('#result-condition-more').hide();
        $('#result-sort').hide();

        if(sc.sc_key == 'gift_show_select'){
          $('#sc-adlist').show();
        }
      }
    });
  }
});

import $ from 'jquery';
import { Lang, translate } from './translate';
import { getParam } from './urlParam';
import { conditions, langList } from '../variables';

$(() => {
  $('.trn-lang').on('click', function () {
    const lang = $(this).data('value') as keyof Lang;
    localStorage.setItem('lang', lang);
    window.location.href = '?lang='+lang;
  });
  // クエリ取得
  const pLang = getParam('lang');

  // 言語設定 クエリ優先 -> localStorage
  let lang = 'ja' as keyof Lang;
  if (langList.indexOf(String(pLang)) !== -1) {
    lang = pLang as keyof Lang;
    localStorage.setItem('lang', lang);
  }else{
    lang = (localStorage.getItem('lang') ?? 'ja') as keyof Lang;
  }
});
